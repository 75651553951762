<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-12">
                    <div class="card mb-4 bg-secondary shadow">
                        <div class="card-header">
                            <div class="row">
                                <div class="col">
                                    <h3 class="mb-0">{{ tt('change_password') }}</h3>
                                </div>
                                <div class="col text-right">
                                    <base-button size="sm" type="danger" @click="update" :disabled="btnUpdate.onLoading">{{ tt('update') }}</base-button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body" style="height: 570px">
                            <base-alert type="danger" v-if="errorMessage">
                                <p><strong>{{ errorMessage.message }}</strong></p>
                                <div v-if="errorMessage.data.length != 0">
                                    <span v-for="message in errorMessage.data">
                                        {{ message[0] }}<br>
                                    </span>
                                </div>
                            </base-alert>                            
                            <div class="row">
                                <div class="col-12">
                                    <base-input type="password":label="tt('current_password')" :name="tt('current_password')" :placeholder="tt('current_password')" v-model="dataChangePassword.current_password" rules="required"></base-input>
                                </div>
                                <div class="col-12">
                                    <base-input type="password" :label="tt('new_password')" :name="tt('new_password')" :placeholder="tt('new_password')" v-model="dataChangePassword.new_password" rules="required"></base-input>
                                </div>
                                <div class="col-12">
                                    <base-input type="password" :label="tt('confirm_new_password')" :name="tt('confirm_new_password')" :placeholder="tt('confirm_new_password')" v-model="dataChangePassword.new_password_confirmation" rules="required"></base-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>       
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import userProfile from '@/services/userProfile/userProfile.service';

    export default {        
        data() {
            return {               
                btnUpdate: {
                    onLoading: false
                },
                form: {
                    show: false
                }, 
                errorMessage: null,     
                dataChangePassword: {}  
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
        },
        methods: {
            update() { 
                let context = this;  
                this.btnUpdate.onLoading = true;  
                this.errorMessage = null;                
                Api(context, userProfile.change_password(context.dataChangePassword)).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });   
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnUpdate.onLoading = false;
                }).call();
            }
        },
    };
</script>
<style></style>
